.pageTopImage {
  width: 100%;
  height: auto;
  margin-bottom: 12px;
  /*  margin-top: 50px;  */
  /*  border-radius: 5%; */
  overflow: hidden;
  border-width: 3;
  border-color: red;
}

.pageTopBottomSpace {
  margin-bottom: 50px;
}

.pageListBottomSpace {
  margin-bottom: 5px;
}

.tableImage {
  width: 190px;
}
